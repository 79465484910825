.wider-card {
    width: 100%;
    /* Adjust the width as needed, e.g., 80% of the parent container */
    margin: 0 auto;
    /* Center the card horizontally */
    margin-bottom: '4rem';
}


/* CSS class for pink color (female) */
.gender-col {
    font-size: 1.2rem;
    /* Increase the font size as needed */
    color: #333;
    /* Adjust the color if necessary */
}

/* Define styles for the icon colors */
.blue-icon {
    color: blue;
}

.pink-icon {
    color: pink;
}

.dog-card {

    padding: 16px;
    margin-bottom: 16px;

}

.dog-info-section {
    background-color: #fff !important;
    /* Use the desired background color */
}


.dog-image {
    max-width: 100%;
    margin: 0 auto;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}

.dog-info-body {
    /* Add styles to differentiate and style this section */
    background-color: #f9f9f9;
    padding: 16px;

}

.dog-name {
    font-size: 1.5rem;
    margin-bottom: 8px;
    color: #333;
}

.dog-info-body .card-text {
    font-size: 1rem;
    color: #666;
}

.dog-info-body .card-text div {
    margin-bottom: 4px;
}

/* Add any other styles you need for differentiation and styling */


.dog-info {
    font-size: 1.2rem;
    /* Increase the font size for the entire section */

    color: #666;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.dog-info-item {
    display: flex;
    align-items: center;
    gap: 4px;
}

.info-label {

    /* Make the labels bold */
    color: #333;
}

/* Adjust the style of the values */
.dog-info-item strong {
    font-weight: bold;
    /* Make the values bold */
    color: #333;
    /* Adjust the color of the values */
    font-size: 1.1rem;
    /* Increase the font size of the values */
}



.dog-info div {
    margin-bottom: 4px;
}

.date-listed-col {
    font-size: 0.8rem;
    /* Decrease the font size as needed */
    color: #666;
    /* Adjust the color if necessary */
}

.date-listed-col strong {
    font-weight: bold;
    /* Keep the strong text bold */
    font-size: 0.8rem;
    /* Adjust the font size of the strong text if necessary */
    color: #333;
    /* Adjust the color if necessary */
}

.breed-label {

    font-style: italic;
    /* Add italic style */
    color: #007bff;
    /* You can adjust the color as needed */
    font-size: 1.2rem;
    /* You can adjust the font size as needed */
    margin-right: 4px;
    /* Add spacing to the right of the label */
}


.centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center the content vertically */
}

.wider-card {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    height: 100% !important;
    /* Full height */
    width: 600px;
    /* Default width for larger screens */
}

/* Media query for smaller screens */
@media (max-width: 767px) {

    /* Adjust this breakpoint as needed */
    .wider-card {
        width: 100%;
        /* Full width on smaller screens */
        /* Additional adjustments for smaller screens can be added here */
    }
}


.dog-info-section {
    flex: 1 !important;
    /* Allows this section to grow and fill available space */
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    /* Useful if you have multiple sections */
}


/* Base card styles */
.wider-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 3px solid #c7c7c7 !important;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    background-image: url('../../assets/puppies.png') !important;
    /* Replace with your image path */
    background-repeat: no-repeat;
    background-size: cover;
    /* Cover the entire card */
    background-position: center;
    /* Center the background image */

}

/* Hover effect */
.wider-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

/* Customizing the card header and footer */
.card-header,
.card-footer {
    background-color: #f8f8f8;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

/* If you want to add a gradient or any other style to the card background */
.card-body {
    background: linear-gradient(to bottom, #fff, #f8f8f8);
}