.btn-on-top {
    z-index: 9999;
    /* You can adjust this value if needed */
    position: relative;
    /* Ensure the element has a position value other than 'static' for z-index to take effect */
}

.bg-body-tertiary {
    background-color: #e8e8e8 !important;
}




.auth-buttons {
    display: flex !important;
}