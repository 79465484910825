.chat-window {
    height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 10px;
}



.bully-avatar {
    max-width: 100px !important;
    /* Adjust the size as needed */
    margin-bottom: 20px;
    /* Add some space below the avatar */
}

.avatar-container {
    display: flex;
    align-items: start;
    /* Align to the top */
    justify-content: center;
    /* Center horizontally */
}

.chat-window {
    height: 300px;
    overflow-y: auto;
    border: none;
    padding: 15px;
    margin-bottom: 10px;
}

.message-sent {
    color: white;
    background-color: #0A84FF;
    /* iPhone blue */
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    max-width: 60%;
    align-self: flex-end;
}

.message-received {
    background-color: #E5E5EA;
    /* Light gray */
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    max-width: 60%;
}

.chat-message {
    padding: 10px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
    max-width: 75%;
    word-wrap: break-word;
}

.user-message {
    background-color: #0A84FF;
    /* Blue color for user messages */
    color: white;
    margin-left: auto;
    /* Align to the right */
    border-bottom-right-radius: 0;
}

.ai-message {
    background-color: #E5E5EA;
    /* Light gray for AI messages /
  color: black;
  margin-right: auto; / Align to the left */
    border-bottom-left-radius: 0;
}

.typing-indicator {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 0 3px;
    background-color: #9b9b9b;
    border-radius: 50%;
    display: inline-block;
    animation: blink 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
    animation-delay: -0.32s;
}

.dot:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes blink {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1.0);
    }
}

.avatar {
    width: 300px;
    /* Adjust as needed */
    height: 300px;
    /* Adjust as needed */
    border-radius: 50%;
    /* Makes it circular */
    background-color: white;
    /* Or any other color */
    border: 2px solid #ccc;
    /* Optional border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional shadow */
}

.avatar:hover {
    transform: scale(1.1);
    /* Slightly enlarges the avatar on hover */
    transition: transform 0.3s ease-in-out;
}

.back-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    /* Change as per your color scheme */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #0056b3;
    /* Darken color on hover */
}