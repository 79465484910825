/* Home.css */
.card img {
    height: 200px;
    object-fit: cover;
}

/* Any other specific styles */
.mt-4,
.container {}

.dogBg {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    /* This distributes space evenly */
    height: 100% !important;
    /* Full height */
    width: 100% !important;
}