body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/puppies1.png') !important;
  background-repeat: repeat !important;
  background-size: contain !important;
  /* or use specific dimensions */
  background-attachment: fixed !important;
  background-position: center center !important;

}

.amplify-button {
  font-size: 2rem;
  padding: 1rem 2rem;
  background: blue !important;
  color: white !important;

  border: 2px solid black;
}

/* Style for the active tab item */
.amplify-tabs-item:focus,
.amplify-tabs-item:active {
  color: black !important;
  border-color: blue !important;
}

.amplify-tabs-item:first-child {
  color: black !important;
  border-color: blue !important;
}

.amplify-tabs-item:first-child:hover {
  color: black !important;
}

.amplify-tabs-item:hover {
  color: black !important;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}