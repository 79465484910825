.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.app-container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  min-height: 100vh !important;
  /* This will make the container take up the entire viewport height */
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
}

.nav-link {
  text-decoration: none;
  color: #333;
  padding: 10px 20px;
  border: 1px solid #333;
  margin: 0 10px;
  border-radius: 5px;
}

.nav-link:hover {
  background-color: #333;
  color: #fff;
}

body,
html {
  overflow-x: hidden !important;
  /* This ensures that there's no horizontal scrolling. */
}

body,
html {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  width: 100%;
}


/* Wrap around everything except the footer */
.main-content {
  min-height: calc(100% - 60px);
  /* Assuming footer height is 60px */
  position: relative;
  padding-bottom: 60px;
  /* Equal to footer height */
}